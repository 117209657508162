import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

import SubmissionsView from '@/views/SubmissionsView'
import Login from '@/views/Login'
import ForgottenPasswordView from '@/views/ForgottenPasswordView'
import SignupView from '@/views/SignupView'
import RequestAccessView from '@/views/RequestAccessView'
import ResetPasswordView from '@/views/ResetPasswordView'

import SearchView from '@/views/SearchView'
import ToApprove from '@/views/ToApprove.vue'
import ProfileView from '@/views/ProfileView'
import NotFound from '@/views/NotFound'
import SubmissionView from '@/views/SubmissionView'
import TermsView from '@/views/TermsView'


Vue.use(Router);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requiresVisitor: true,
      requiresDesktop: true
    },
  },
  {
    path: "/forgotten-password",
    name: "forgotten-password",
    component: ForgottenPasswordView,
    meta: {
      requiresVisitor: true,
      requiresDesktop: false
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPasswordView,
    meta: {
      requiresVisitor: true,
      requiresDesktop: false
    },
  },
  {
    path: '/request',
    name: 'request',
    component: RequestAccessView,
    meta: {
      requiresVisitor: true,
      requiresDesktop: false
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupView,
    meta: {
      requiresVisitor: true,
      requiresDesktop: false
    },
  },
  {
    path: "/submissions",
    name: 'submissions',
    component: SubmissionsView,
    meta: {
      requiresAuth: true,
      requiresDesktop: true
    },
  },
  {
    path: '/search',
    name: 'search',
    component: SearchView,
    meta: {
      requiresAuth: true,
      requiresDesktop: true
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: {
      requiresAuth: true,
      requiresDesktop: true
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView,
    meta: {
      requiresAuth: false,
      requiresDesktop: false
    },
  },
  {
    path: '/submission/create',
    name: 'submission.create',
    component: SubmissionView,
    meta: {
      requiresAuth: true,
      requiresDesktop: true
    },
  },
  {
    path: '/submission/:id',
    name: 'submission',
    component: SubmissionView,
    meta: {
      requiresAuth: true,
      requiresDesktop: true
    },
  },
  {
    path: '/toapprove',
    name: 'toapprove',
    component: ToApprove,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      requiresDesktop: true
    },
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound,
  },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {

  if (to.matched.some((record) => record.meta.requiresAuth)) {

    if (!store.getters["account/loggedIn"]) {

      next({ name: 'login', query: { redirect: to.fullPath } });

    } else {

      if (to.matched.some((record) => record.meta.requiresAdmin)) {

        if (!store.getters["account/isAdmin"]) {

          next({ name: "submissions" });

        } else {

          next();

        }

      } else {

        next();

      }

    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {

    if (store.getters["account/loggedIn"]) {

      next({ name: "submissions" });

    } else {

      next();

    }

  } else if (to.path === "/") {

    if (store.getters["account/loggedIn"]) {

      if (!store.getters["account/isAdmin"]) {

        next({ name: "submissions" });

      } else {

        next({ name: "toapprove" });

      }
    } else {

      next({ name: "search" });

    }

  }else if(to.path === "/register" || to.path === "/register/") {

    next({ name: 'request' });

  } else {

    next();

  }
});

export default router;
