<template>
  <div>
    <div class="mt-4">
      <h2 class="title">Create a release</h2>
      <h3 class="text-xl my-4">Search for your release</h3>
      <form class="mt-1 mb-4 flex w-full" @submit.prevent="onClickSearch">
        <div class="relative w-full max-w-screen-sm">
          <input
            class="w-full bg-gray-300 bg-opacity-20 placeholder-gray-400 border-2 border-transparent dark:border-transparent rounded-xl h-12 px-5 pr-16 focus:border-black dark:focus:border-white active:border-black dark:active:border-white focus:outline-none active:outline-none"
            type="text"
            name="search"
            v-model="term"
            placeholder="Artist and release name"/>
          <button
            type="submit"
            class="absolute right-0 top-0"
            :class="{ 'cursor-not-allowed': !term || isSearching}"
            :disabled="!term || isSearching">
            <svg
              v-if="!isSearching"
              class="mt-3 mr-3 h-6 w-6 text-gray-400"
              version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 28 28" style="enable-background:new 0 0 28 28;" xml:space="preserve">
              <g>
                <g>
                  <path fill="currentColor" d="M12.4,6.5c3.25,0,5.9,2.65,5.9,5.9s-2.65,5.9-5.9,5.9s-5.9-2.65-5.9-5.9S9.15,6.5,12.4,6.5 M12.4,4.5
                    c-4.36,0-7.9,3.54-7.9,7.9s3.54,7.9,7.9,7.9s7.9-3.54,7.9-7.9S16.77,4.5,12.4,4.5L12.4,4.5z"/>
                </g>
                <g>
                  <line fill="currentColor" style="fill:none;stroke:currentColor;stroke-width:2;stroke-miterlimit:10;" x1="23.5" y1="23.5" x2="16.79" y2="16.79"/>
                </g>
              </g>
            </svg>
            <div
              v-else
              class="mt-2.5 mr-3 w-8">
              <Loader></Loader>
            </div>
          </button>
        </div>
      </form>
    </div>
    <div class="my-4">
      <AlbumsList v-if="searchResults" :albums="searchResults"></AlbumsList>
      <div v-if="!hasPerformedSearch">
        <div class="mt-8 font-bold">This is where you can add extra artwork for your release on Sleevenote</div>
        <div>Note: you must be the rights holder or have the rights holder's permission, you will be agreeing to your <router-link to="/terms" target="_blank" class="underline hover:no-underline">terms</router-link>.</div>
        <h3 class="mt-8 text-xl">What you'll need</h3>
        <div>• A release available on Spotify.</div>
        <div>• Artwork as jpg(s) at 2000x2000px, compressed to your satisfaction. <a href="https://sleevenote.notion.site/sleevenote/Sleevenote-Artwork-Guidelines-79485d5df93349e0926acec7f22b74a0" target="_blank" class="underline hover:no-underline">More guidelines</a></div>
        <div>• Ready? Search for your release above.</div>
      </div>

      <h2 v-if="searchResults.length == 0 && hasPerformedSearch">
        No matching results
      </h2>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import AlbumsList from "@/components/AlbumsList.vue";

export default {
  name: "Search",
  components: {
    AlbumsList,
  },
  data() {
    return {
      term: "",
      isSearching: false,
      hasPerformedSearch: false,
      serverError: "",
    };
  },
  computed: {
    ...mapState("search", {
      searchResults: (state) => state.searchResults,
    }),
  },
  methods: {
    ...mapMutations("search", {
      setSearchResults: "setSearchResults",
    }),
    ...mapActions("search", {
      performSearch: "performSearch",
    }),
    ...mapActions("account", {
      destroyToken: "destroyToken",
    }),
    submitSearch() {
      if (!this.term) return;
      this.isSearching = true;
      document.title = "Create on Sleevenote - Search: " + this.term;
      this.performSearch(this.term)
        .then((response) => {
          this.setSearchResults(response.data.albums);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            /*this.destroyToken().finally(() => {
              this.$router.replace({
                name: "login",
                query: { redirect: this.$route.fullPath },
              });
            });*/
          }
        })
        .then(() => {
          this.hasPerformedSearch = true;
          this.isSearching = false;
        });
    },
    async onClickSearch() {
      this.hasPerformedSearch = false;
      this.setSearchResults([]);
      //change the query string
      //a watch method checks for this and
      //performs the search
      await this.$router.replace({
        name: "search",
        query: { term: this.term },
      });
    },
  },
  watch: {
    //watch for query string to change and
    //set the search field when it does
    "$route.query.term": function() {
      this.term = this.$route.query.term;
      if (this.term) {
        this.submitSearch();
      } else {
        this.setSearchResults([]);
      }
    },
  },
  mounted() {
    document.title = "Create on Sleevenote - Search"
    //unsure why this is required
    /*if (this.search) {
      this.$router.push({ name: "search", query: { search: this.search } });
    }*/

    //get the query string and
    //set the search field when app mounts
    this.term = this.$route.query.term;
    if (this.term) {
      this.submitSearch();
    } else {
      this.setSearchResults([]);
    }



  },
};
</script>