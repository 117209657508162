<template>
  <div>
    <img class="hidden lg:block fixed top-24 right-0 z-0 h-64" src="@/assets/png/signup img2 right.png" />
    <div class="relative z-1">
      <!--<h1 class="my-4 text-3xl xs:text-5xl text-mint font-extrabold">
        <span>Upload artwork to Sleevenote.</span>
      </h1>-->
      <div class="text-2xl xs:text-3xl mb-8">Reset password</div>

      <form @submit.prevent="submit" class="text-base xs:text-xl max-w-screen-sm">

        <div class="mb-4 flex flex-wrap">
          <div class="w-full flex flex-col md:flex-row justify-between">
            <label class="mr-2 w-40">Confirm email</label>
            <input
              v-model="email"
              type="text"
              required
              class="flex-grow bg-gray-200 p-2 rounded-none outline-none text-black">
            </div>
          <div
            v-if="errors.email"
            class="text-sm xs:text-lg text-red-500 w-full max-w-md">
            {{ errors.email.join(',') }}
          </div>
        </div>

        <div class="mb-4 flex flex-wrap relative">
          <div class="w-full flex flex-col md:flex-row justify-between">
            <label class="mr-2 w-40">Password</label>
            <input
              v-model="password"
              type="password"
              required
              class="flex-grow bg-gray-200 p-2 rounded-none outline-none text-black">
          </div>
          <div
            v-if="errors.password"
            class="text-sm xs:text-lg text-red-500 w-full max-w-md">
            {{ errors.password.join(',') }}
          </div>
        </div>

        <div>
          <button
            type="submit"
            :disabled="loading"
            class="text-white bg-mint shadow-md rounded-xl hover:shadow-sm transition-colors p-2 focus:outline-none w-64 h-12 mb-4"
            :class="{'cursor-not-allowed': loading, '': !loading}">
            <span v-if="!loading">GO</span>
            <span v-else>
              <Loader></Loader>
            </span>
          </button>
        </div>

        <div v-if="!loading && passwordReset" class="text-sm xs:text-lg text-green-500 w-full max-w-md">
          Success! Password set. You may now <router-link :to="{ name: 'login' }" class="border-b-2 border-green-500 hover:border-transparent">log in</router-link> with your new password.
        </div>
        <div
          v-else-if="errors.length > 0"
          class="text-sm xs:text-lg text-red-500 w-full max-w-md">
          {{ errors.join(',') }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data(){
    return{
      email: null,
      password: null,
      token: null,
      errors: {},
      loading: false,
      passwordReset: false
    }
  },
  methods: {
    submit(){
      this.loading = true;
      axios.post(`${process.env.VUE_APP_API_URL}/creator/reset-password`, {
        email: this.email,
        password: this.password,
        token: this.token
      })
      .then(() => {
        this.passwordReset = true
        this.errors = {}
      })
      .catch((error) => {
        this.errors = error.response.data.errors
        this.passwordReset = false
      })
      .finally(() => {
        this.loading = false
      })
    }
  },
  mounted(){
    this.token = this.$route.query.token;
  },
  created() {
  }
};
</script>
