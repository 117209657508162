<template>
  <div>
    <img class="hidden lg:block fixed top-24 right-0 z-0 h-64" src="@/assets/png/signup img2 right.png" />
    <div class="relative z-1">
      <h1 class="my-4 text-3xl xs:text-5xl text-mint font-extrabold">
        <span>Create releases on Sleevenote</span>
      </h1>
      <div class="text-2xl xs:text-3xl mb-8">Log in</div>

      <form @submit.prevent="submit" class="text-base xs:text-xl max-w-screen-sm">

        <div class="mb-4 flex flex-wrap">
          <div class="w-full flex flex-col md:flex-row justify-between">
            <label class="mr-2 w-40">Email</label>
            <input
              v-model="email"
              type="text"
              required
              class="flex-grow bg-gray-200 p-2 rounded-none outline-none text-black">
            </div>
          <div
            v-if="errors.email"
            class="text-sm xs:text-lg text-red-500 w-full max-w-md">
            {{ errors.email.join(',') }}
          </div>
        </div>

        <div class="mb-4 flex flex-wrap relative">
          <div class="w-full flex flex-col md:flex-row justify-between">
            <label class="mr-2 w-40">Password</label>
            <input
              v-model="password"
              type="password"
              required
              class="flex-grow bg-gray-200 p-2 rounded-none outline-none text-black">
          </div>
          <div
            v-if="errors.password"
            class="text-sm xs:text-lg text-red-500 w-full max-w-md">
            {{ errors.password.join(',') }}
          </div>
        </div>

        <div class="text-sm xs:text-lg mb-4 flex flex-wrap relative">
          <div class="w-full flex justify-end">
            <router-link
              :to="{ name: 'forgotten-password' }"
              class="underline decoration-4 hover:no-underline">Forgot</router-link>
          </div>
        </div>

        <div>
          <button
            type="submit"
            :disabled="loading"
            class="text-white bg-mint shadow-md rounded-xl hover:shadow-sm transition-colors p-2 focus:outline-none w-64 h-12 mb-4"
            :class="{'cursor-not-allowed': loading, '': !loading}">
            <span v-if="!loading">GO</span>
            <span v-else>
              <Loader></Loader>
            </span>
          </button>

          <div class="mt-10 text-base xs:text-lg">Don't have an account? <router-link
            :to="{ name: 'request' }"
            class="underline decoration-4 hover:no-underline">Request one here</router-link>
          </div>
        </div>

      </form>
    </div>
    <img class="-mx-4 h-auto max-h-64 mt-12" src="@/assets/png/signup img1 left.png" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      email: '',
      password: '',
      errors: {},
      loading: false,
    };
  },
  methods: {
    ...mapState('account', ['user']),
    ...mapActions("account", ["retrieveToken", "sendPasswordResetEmail"]),
    submit() {
      this.loading = true;
      this.retrieveToken({
        email: this.email,
        password: this.password,
      })
      .then(() => {
        this.loading = false;
        if (this.$store.state.account.user.is_admin) {
          this.$router.push("/toapprove");
        } else {
          this.$router.push(this.$route.query.redirect || "/submissions");
        }
      })
      .catch((error) => {
        this.loading = false;
        this.errors = error.response.data.message
        this.password = "";
      });
    },
  },
};
</script>