<template>
  <div id="canvas-container" class="canvas">
    <canvas
      id="canvas"
      @mousedown="mousedown"
      @mousemove="mousemove"
      @mouseup="mouseup"
      @mouseleave="mouseleave"
      @mouseenter="mouseenter"
    ></canvas>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { Rectangle, Polygon } from "@/polygon.js";
import { getPosition, mouseHasMoved } from "@/helpers.js";
import CONSTANTS from "@/constants";

export default {
  name: "Canvas",
  props: {
    hijackKeys: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      drawMode: "RECT",
      editMode: "MOVE",
      context: null,
      activePoly: -1,
      bExpectNewPoly: true,
      bMouseDown: false,
      bInsidePolygon: false,
      hitAnchor: -1,
      startPos: null,
      mousePos: null,
      dragPos: null,
      bAltPressed: false,
      bIsEnabled: false,
    };
  },
  mounted() {
    let canvas = document.getElementById("canvas");
    canvas.width = CONSTANTS.CANVAS_SIZE;
    canvas.height = CONSTANTS.CANVAS_SIZE;
    this.context = canvas.getContext("2d");
    this.startAnimating(30);
    this.windowResized();
    this.changeCursor();

    this.$root.$on("trackDragend", this.trackDragend);

    this.show();
  },
  beforeDestroy() {
    this.$root.$off("trackDragend", this.trackDragend);
  },
  computed: {
    ...mapState("album", {
      album: (state) => state.album,
      polygons: (state) => state.polygons,
    }),
  },
  methods: {
    ...mapMutations("album", {
      setPolygons: "setPolygons",
    }),
    trackDragend(data) {
      if (!this.bIsEnabled) return;
      //console.log('trackDragend',data)
      if(!data.track)return;
      //let pos = getPosition(this.dragPos, this.context);

      //did we hit a polygon?
      let polyHit = this.insidePolygon(this.dragPos);

      this.polygons.forEach((poly) => {
        poly.bTrackHoveringOver = false;
      });

      if (polyHit > -1) {
        this.$root.$emit("activateTrack", data.track, this.polygons[polyHit]);
      }
      //e.preventDefault();
    },
    setPosAndSize(l, t, w, h) {
      let container = document.getElementById("canvas-container");
      container.style.left = l;
      container.style.top = t;

      this.setSize(w, h);
    },
    setSize(w, h) {
      /*let container = document.getElementById("canvas-container");
      container.style.width = w + "px";
      container.style.height = h + "px";
      let canvas = document.getElementById("canvas");
      canvas.width = w;
      canvas.height = h;*/
      //var canvas = document.getElementById("canvas");
      //canvas.height = canvas.width;
      console.log(w + " " + h);
    },
    show() {
      let container = document.getElementById("canvas-container");
      container.style.display = "block";

      let canvas = document.getElementById("canvas");
      canvas.style.display = "block";
    },
    hide() {
      let container = document.getElementById("canvas-container");
      container.style.display = "none";

      let canvas = document.getElementById("canvas");
      canvas.style.display = "none";
    },
    enable() {
      this.bIsEnabled = true;
    },
    disable() {
      this.bIsEnabled = false;
    },
    windowResized() {
      //let container = document.getElementById("canvas-container");
      //let canvas = document.getElementById("canvas");
      //canvas.width = container.parentElement.clientWidth;
      //canvas.height = container.parentElement.clientWidth;
      //canvas.style.width = container.parentElement.clientWidth + "px";
      //canvas.style.height = container.parentElement.clientHeight + "px";
      //console.log(canvas.width);
    },
    changeMode: function(mode) {
      if (this.drawMode === mode) return;

      this.deleteOpenPolys();

      this.drawMode = mode;
      this.bExpectNewPoly = true;
      if (this.drawMode == "EDIT") {
        this.activePoly = this.polygons.length - 1;
        /*this.polygons.forEach(function(poly) {
          poly.generateInterpolatedPositions();
        });*/
      } else {
        this.activePoly = -1;
      }
      this.changeCursor();
    },
    deleteOpenPolys() {
      if (this.activePoly > -1) {
        if (!this.polygons[this.activePoly].bIsClosed) {
          let poly = this.polygons[this.activePoly];
          this.polygons.splice(this.activePoly, 1);
          this.$root.$emit("polyErased", poly);
        }
      }
    },
    changeCursor() {
      let canvas = document.getElementById("canvas");

      canvas.classList.remove("crosshair");
      canvas.classList.remove("pointer");
      canvas.classList.remove("eraser");
      canvas.classList.remove("duplicate");
      canvas.classList.remove("horizontal");
      canvas.classList.remove("vertical");

      if (this.bAltPressed) {
        canvas.classList.add("duplicate");
        return;
      }

      if (this.drawMode == "RECT" || this.drawMode == "POLY") {
        canvas.classList.add("crosshair");
      } else if (this.drawMode == "EDIT") {
        if (this.editMode == "HORIZ") {
          canvas.classList.add("horizontal");
        } else {
          canvas.classList.add("pointer");
        }
      } else if (this.drawMode == "ERASE") {
        canvas.classList.add("eraser");
      }
    },
    clear: function() {
      //this.polygons = [];
      this.setPolygons([]);
      this.activePoly = -1;
    },
    dragover: function(e) {
      if (!this.bIsEnabled) return;
      //console.log(e);
      let pos = getPosition(e, this.context);
      this.dragPos = pos;

      let polyHit = this.insidePolygon(this.dragPos);
      if (polyHit > -1) {
        for (let i = 0; i < this.polygons.length; i++) {
          if (i == polyHit) {
            this.polygons[i].bTrackHoveringOver = true;
          } else {
            this.polygons[i].bTrackHoveringOver = false;
          }
        }
      } else {
        this.polygons.forEach((poly) => {
          poly.bTrackHoveringOver = false;
        });
      }

      e.preventDefault();
    },
    checkErasePoint: function(pos) {
      let deltaX = pos.x - this.startPos.x;
      let deltaY = pos.y - this.startPos.y;
      if (
        Math.abs(deltaX) < CONSTANTS.HANDLE_SIZE &&
        Math.abs(deltaY) < CONSTANTS.HANDLE_SIZE
      ) {
        this.polygons.forEach(function(poly) {
          let i = poly.hitAnchor(pos, CONSTANTS.HANDLE_SIZE);
          if (i > -1) {
            poly.removePoint(i);
            //no way to break out of a foreach!
          }
        });
      }
    },
    checkErasePoly: function(pos) {
      let self = this;
      this.polygons.forEach(function(poly, index) {
        let i = -1;
        if (poly.insidePolygon(pos)) {
          i = index;
        }
        if (i > -1) {
          let poly = self.polygons[i];
          self.polygons.splice(i, 1);
          self.activePoly = self.polygons.length - 1;
          self.$root.$emit("polyErased", poly);
          //no way to break out of a foreach!
        }
      });
    },
    deleteLastPoly() {
      let poly = this.polygons[this.polygons.length - 1];
      this.polygons.pop();
      this.activePoly = this.polygons.length - 1;
      this.$root.$emit("polyErased", poly);
    },
    deleteSelectedPoly() {
      let poly = this.polygons[this.activePoly];
      this.polygons.splice(this.activePoly, 1);
      this.activePoly = this.polygons.length - 1;
      this.$root.$emit("polyErased", poly);
    },
    deleteLastPoint: function() {
      console.log("deleteLastPoint");
      console.log(this.polygons[this.activePoly].points);
      this.polygons[this.activePoly].points.pop();
      console.log(this.polygons[this.activePoly].points);
    },
    checkAddPolyPoint: function(pos) {
      if (this.bExpectNewPoly) {
        let p = new Polygon();
        p.addPoint(pos);
        this.polygons.push(p);
        this.activePoly = this.polygons.length - 1;
        this.bExpectNewPoly = false;
        return;
      } else {
        this.polygons[this.activePoly].addPoint(pos);
        if (this.polygons[this.activePoly].bIsClosed) {
          //this.activePoly = -1;
          this.bExpectNewPoly = true;
        }
      }
    },
    checkCreateRect: function(pos) {
      if (this.bExpectNewPoly) {
        let deltaX = pos.x - this.startPos.x;
        let deltaY = pos.y - this.startPos.y;
        if (
          Math.abs(deltaX) < CONSTANTS.HANDLE_SIZE &&
          Math.abs(deltaY) < CONSTANTS.HANDLE_SIZE
        ) {
          let p = new Rectangle();
          p.addPoint(pos);
          this.polygons.push(p);
          this.bExpectNewPoly = false;
          this.activePoly = this.polygons.length - 1;
        } else {
          let p = new Rectangle();
          p.addPoint(this.startPos);
          this.polygons.push(p);
          let topLeft = p.points[0];
          p.addPoint({
            x: pos.x,
            y: topLeft.y,
          });
          p.addPoint({
            x: pos.x,
            y: pos.y,
          });
          p.addPoint({
            x: topLeft.x,
            y: pos.y,
          });
          p.bIsClosed = true;
          this.bExpectNewPoly = true;
          this.activePoly = this.polygons.length - 1;
        }
      } else {
        if (this.activePoly > -1) {
          let topLeft = this.polygons[this.activePoly].points[0];
          this.polygons[this.activePoly].addPoint({
            x: pos.x,
            y: topLeft.y,
          });
          this.polygons[this.activePoly].addPoint({
            x: pos.x,
            y: pos.y,
          });
          this.polygons[this.activePoly].addPoint({
            x: topLeft.x,
            y: pos.y,
          });
          this.polygons[this.activePoly].bIsClosed = true;
          this.bExpectNewPoly = true;
        }
      }
    },
    duplicatePoly: function(poly) {
      /*let newPoly = { ...poly };
      poly.bIsMouseOver = false;
      poly.bTrackHoveringOver = false;
      poly.bMovingPoly = false;
      poly.hoverAnchor = -1;
      poly.draggingAnchor = -1;
      this.polygons.push(newPoly);
      this.activePoly = this.polygons.length - 1;*/
      if (poly.bIsRectangle) {
        let newPoly = new Rectangle();
        poly.points.forEach(function(point) {
          newPoly.addPoint({ x: point.x, y: point.y });
        });
        this.polygons.push(newPoly);

        newPoly.bIsClosed = true;
        this.activePoly = this.polygons.length - 1;
      } else {
        let newPoly = new Polygon();
        poly.points.forEach(function(point) {
          newPoly.addPoint({ x: point.x, y: point.y });
        });
        this.polygons.push(newPoly);

        newPoly.bIsClosed = true;
        this.activePoly = this.polygons.length - 1;
      }
    },
    createNewPolygon: function(pos) {
      let p = new Polygon();
      p.addPoint(pos);
      this.polygons.push(p);
      return p;
    },
    insidePolygon: function(pos) {
      let i = -1;
      this.polygons.forEach(function(poly, index) {
        if (poly.insidePolygon(pos)) {
          poly.bInsidePolygon = true;
          i = index;
        } else {
          poly.bInsidePolygon = false;
        }
      });
      return i;
    },
    hitActivePolygonAnchor: function(pos, handleSize) {
      let i = -1;
      if (this.activePoly == -1) return i;

      let anchor = this.polygons[this.activePoly].hitAnchor(pos, handleSize);
      if (anchor != -1) {
        this.polygons[this.activePoly].draggingAnchor = anchor;
        i = anchor;
      }
      return i;
    },
    checkLockPoints(poly, pos) {
      //lock points
      this.polygons.forEach((p) => {
        if (poly !== p) {
          p.points.forEach((point) => {
            if (Math.abs(point.x - pos.x) < 3) {
              pos.x = point.x;
            }

            if (Math.abs(point.y - pos.y) < 3) {
              pos.y = point.y;
            }
          });
        }
      });
    },
    mousedown: function(e) {
      if (!this.bIsEnabled) return;
      this.bMouseDown = true;

      let pos = getPosition(e, this.context);
      this.startPos = pos;

      this.bInsidePolygon = false;

      //check whether we have hit a polygon anchor
      this.hitAnchor = this.hitActivePolygonAnchor(pos, CONSTANTS.HANDLE_SIZE);

      //check whether we have hit inside a polygon
      let polyInside = this.insidePolygon(pos);

      //if alt is pressed and we're inside a polygon
      //but not ontop of an anchor point
      //then we want to duplicate
      if (this.bAltPressed && polyInside > -1 && this.hitAnchor == -1) {
        this.duplicatePoly(this.polygons[polyInside]);
        return;
      }

      if (this.drawMode == "POLY") {
        console.log("checkAddPolyPoint");
        this.checkAddPolyPoint(pos);
      } else {
        if (polyInside > -1 && this.hitAnchor == -1) {
          if (this.polygons[polyInside].bIsClosed) {
            this.activePoly = polyInside;

            if (this.drawMode == "ERASE") {
              this.checkErasePoly(pos);
              return;
            } else if (this.drawMode == "EDIT") {
              this.bInsidePolygon = true;
              //this.drawMode = "EDIT";
              //this.$emit("modeChanged", this.drawMode);
              return;
            }
          }
        }
      }
    },
    mousemove: function(e) {
      if (!this.bIsEnabled) return;
      //if (this.drawMode == mode.NONE) return;

      let pos = getPosition(e, this.context);
      /*if (this.activePoly > -1) {
        this.polygons[this.activePoly].setMouse(pos);
      }*/

      this.polygons.forEach(function(poly) {
        poly.setMouse(pos);

        if (poly.insidePolygon(pos)) {
          poly.bIsMouseOver = true;
          //if (poly.track) poly.track.bIsMouseOver = true;
        } else {
          poly.bIsMouseOver = false;
          //if (poly.track) poly.track.bIsMouseOver = false;
        }
      });

      if (this.drawMode == "ERASE") return;

      if (this.bAltPressed && this.bMouseDown === true) {
        let deltaX = pos.x - this.mousePos.x;
        let deltaY = pos.y - this.mousePos.y;
        this.polygons[this.activePoly].movePoly(deltaX, deltaY);
        this.mousePos = pos;
        return;
      }

      if (this.bMouseDown === true) {
        if (this.drawMode == "EDIT") {
          if (this.hitAnchor > -1) {
            let poly = this.polygons[this.activePoly];
            if (poly.draggingAnchor != -1) {
              //this.checkLockPoints(poly, pos);

              if (poly.bIsRectangle) {
                poly.points[poly.draggingAnchor] = {
                  x: pos.x,
                  y: pos.y,
                };
                if (poly.draggingAnchor == 0) {
                  poly.points[1].y = pos.y;
                  poly.points[3].x = pos.x;
                } else if (poly.draggingAnchor == 1) {
                  poly.points[0].y = pos.y;
                  poly.points[2].x = pos.x;
                } else if (poly.draggingAnchor == 2) {
                  poly.points[3].y = pos.y;
                  poly.points[1].x = pos.x;
                } else if (poly.draggingAnchor == 3) {
                  poly.points[2].y = pos.y;
                  poly.points[0].x = pos.x;
                }
              } else {
                poly.points[poly.draggingAnchor] = {
                  x: pos.x,
                  y: pos.y,
                };
              }
            }
            return;
          }

          if (this.bInsidePolygon === true) {
            let deltaX = pos.x - this.mousePos.x;
            let deltaY = pos.y - this.mousePos.y;
            this.polygons[this.activePoly].movePoly(deltaX, deltaY);

            this.mousePos = pos;
            return;
          }
        }
      } else {
        if (this.drawMode == "EDIT") {
          /*if (this.polygons[this.activePoly].hitLine(pos) == 0) {
            console.log("horiz");
            this.editMode = "HORIZ";
            this.changeCursor();
          } else {
            this.editMode = "MOVE";
            this.changeCursor();
          }*/
        }
      }

      this.mousePos = pos;
    },
    mouseup: function(e) {
      if (!this.bIsEnabled) return;
      if (!this.bMouseDown) return;
      this.bMouseDown = false;
      let pos = getPosition(e, this.context);
      this.mousePos = pos;
      //this.activePoly = -1;
      /*this.polygons.forEach(function(poly) {
        poly.draggingAnchor != -1;
      });*/
      this.polygons.forEach(function(poly) {
        poly.bIsMouseOver = false;
      });

      let bHasMoved = mouseHasMoved(this.startPos, this.mousePos, 10);
      if (!bHasMoved) {
        /*this.polygons.forEach(function(poly) {
          poly.bIsActive = false;
        });

        if (this.bInsidePolygon) {
          this.polygons[this.activePoly].bIsActive = true;
        }
        return;*/
      }

      if (this.bAltPressed) return;

      switch (this.drawMode) {
        case "RECT":
          //if (this.bInsidePolygon) {
          //this.polygons[this.activePoly].bIsActive = true;
          //  return;
          //}
          //if (this.hitAnchor > -1) return;
          this.checkCreateRect(pos);
          break;
      }
    },
    mouseenter: function() {},
    mouseleave: function() {
      this.polygons.forEach(function(poly) {
        poly.bIsMouseOver = false;
      });
    },
    startAnimating: function(fps) {
      this.fpsInterval = 1000 / fps;
      this.prevTime = Date.now();
      this.animate();
    },
    animate: function() {
      // request another frame
      requestAnimationFrame(this.animate);

      // calc elapsed time since last loop
      let now = Date.now();
      let elapsed = now - this.prevTime;

      // if enough time has elapsed, draw the next frame
      if (elapsed > this.fpsInterval) {
        // Get ready for next frame by setting then=now, but also adjust for your
        // specified fpsInterval not being a multiple of RAF's interval (16.7ms)
        this.prevTime = now - (elapsed % this.fpsInterval);

        this.draw();
      }
    },
    draw: function() {
      let self = this;

      //Clear the canvas
      this.context.clearRect(
        0,
        0,
        this.context.canvas.width,
        this.context.canvas.height
      );

      //self.context.lineJoin = "round";
      self.context.lineWidth = 1;

      //draw all polys which are not active
      this.polygons.forEach(function(poly, index) {

        if (poly.track) {

          let highlight = false;
          self.album.tracks.forEach(function(track) {
            if (track.polygon == poly) {
              if (track.bIsMouseOver) {
                highlight = true;
              }
            }
          });

          if (highlight) {
            poly.draw(self.context, true, self.drawMode);
          } else {
            if (self.drawMode == "ERASE") {
              if (poly.bIsMouseOver) {
                poly.draw(self.context, true, self.drawMode);
              } else {
                poly.draw(self.context, false, self.drawMode);
              }
            }else{
              poly.draw(self.context, false, self.drawMode);
            }


          }

          //draw text for tracks only for admin users
          /*if (self.isAdmin()) {
            poly.drawTrackText(self.context);
          }*/
        } else if (self.drawMode == "RECT" || self.drawMode == "POLY") {
          poly.draw(self.context, false, self.drawMode);
        } else if (self.drawMode == "EDIT") {
          if (self.activePoly > -1 && index != self.activePoly) {
            poly.draw(self.context, false, self.drawMode);
          }
        } else if (self.drawMode == "ERASE") {
          if (poly.bIsMouseOver) {
            poly.draw(self.context, true, self.drawMode);
          } else {
            poly.draw(self.context, false, self.drawMode);
          }
        }
      });

      //draw active poly at top
      if (this.activePoly > -1 && this.drawMode == "EDIT") {
        this.polygons[this.activePoly].drawDropShadow(
          this.polygons[this.activePoly].points,
          this.context,
          1,
          1
        );
        this.polygons[this.activePoly].draw(this.context, true);
        this.polygons[this.activePoly].drawHandles(
          this.context,
          CONSTANTS.HANDLE_SIZE
        );
        /*this.polygons[this.activePoly].drawInterpolatedHandles(
          this.context,
          this.handleSize
        );*/
      }

      if (this.bAltPressed) return;

      if (this.drawMode == "POLY") {
        if (this.activePoly == -1) return;

        if (this.polygons[this.activePoly].bIsClosed) return;

        let from = this.polygons[this.activePoly].points[
          this.polygons[this.activePoly].points.length - 1
        ];
        let to = this.mousePos;
        self.context.beginPath();
        self.context.moveTo(from.x, from.y);
        self.context.lineTo(to.x, to.y);
        self.context.closePath();
        self.context.strokeStyle = "rgb(0,0,255)";
        self.context.stroke();
      } else if (this.drawMode == "RECT") {
        //don't draw the line if we are dragging a polygon
        if (this.bInsidePolygon) return;
        //if (this.hitAnchor > -1) return;

        if (
          this.bMouseDown ||
          (this.activePoly > -1 && !this.polygons[this.activePoly].bIsClosed)
        ) {
          let w = this.mousePos.x - this.startPos.x;
          let h = this.mousePos.y - this.startPos.y;
          self.context.beginPath();
          self.context.rect(this.startPos.x, this.startPos.y, w, h);
          self.context.strokeStyle = "rgb(0,0,255)";
          self.context.stroke();
          self.context.closePath();
        }
      }
    },
    keydown: function(event) {
      if(!this.hijackKeys)return
      if (event.key == "Backspace") {
        if (this.drawMode == "RECT") {
          this.deleteLastPoly();
        } else if (this.drawMode == "POLY") {
          console.log(this.polygons[this.activePoly]);
          if (this.polygons[this.activePoly].bIsClosed) {
            this.deleteLastPoly();
          } else {
            this.deleteLastPoint();
          }
        } else if (this.drawMode == "EDIT") {
          this.deleteSelectedPoly();
        }

        event.preventDefault();
      }
      if (event.key == "Delete") {
        console.log("DELETE was pressed");
        event.preventDefault();
      }
      if (event.key == "Escape") {
        console.log("ESC was pressed");
        if (this.activePoly > -1) {
          if (!this.polygons[this.activePoly].bIsClosed) {
            this.deleteLastPoly();
            this.bExpectNewPoly = true;
          }
        }

        event.preventDefault();
      }
      if (event.key == "Alt") {
        console.log("ALT is pressed");
        this.bAltPressed = true;
        this.changeCursor();
        event.preventDefault();
      }
    },
    keyup: function(event) {
      if (event.key == "Alt") {
        console.log("ALT is released");
        this.bAltPressed = false;
        this.changeCursor();
        event.preventDefault();
      }
    },
  },
  created: function() {
    //document.addEventListener("dragend", this.dragend);
    document.addEventListener("dragover", this.dragover);
    window.addEventListener("resize", this.windowResized);
    document.addEventListener("keydown", this.keydown);
    document.addEventListener("keyup", this.keyup);
  },
  destroyed: function() {
    //document.removeEventListener("dragend", this.dragend);
    document.removeEventListener("dragover", this.dragover);
    window.removeEventListener("resize", this.windowResized);
    document.removeEventListener("keydown", this.keydown);
    document.removeEventListener("keyup", this.keyup);
  },
};
</script>

<style scoped>
#canvas {
  width: 100%;
  height: 100%;
}
.canvas {
  position: absolute;
  background: rgba(255, 200, 200, 0);
  width: 68vh;
  height: 68vh;
}
.crosshair {
  cursor: url("../assets/png/crosshair.png") 11 11, auto;
}
.pointer {
  cursor: url("../assets/png/pointer.png") 11 11, auto;
}
.eraser {
  cursor: url("../assets/png/eraser.png") 3 8, auto;
}
.duplicate {
  cursor: url("../assets/png/duplicate.png") 0 0, auto;
}
.horizontal {
  cursor: url("../assets/png/horizontal.png") 11 11, auto;
}
.vertical {
  cursor: url("../assets/png/vertical.png") 11 11, auto;
}
</style>
